import Layout from "./layout";
import { Redirect, Route, Switch } from "wouter";
import { Signin } from ":src/modules/account";
export default function AuthRouter() {
  return (
    <Layout>
      <Switch>
        <Route path="/signin" children={<Signin />} />
        <Route>
          <Redirect to="/signin" />
        </Route>
      </Switch>
    </Layout>
  );
}
